<template>
  <div>
    <div id="centreLeft1Chart" style="width: 3.25rem; height: 3.85rem"></div>
  </div>
</template>

<script>
import { getVpdn } from "../api/api";
import echartMixins from "@/utils/resizeMixins";
export default {
  data() {
    return {
      chart: null,
      single: [],
      multiple: [],
      // zncj: "",
      mouse: {
        color: [],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        toolbox: {
          show: true,
        },
        calculable: true,
        legend: {
          orient: "horizontal",
          icon: "circle",
          top: 15,
          x: "center",
          data: [],
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            name: "厂站类型:",
            type: "pie",
            radius: [10, 60],
            roseType: "area",
            center: ["50%", "40%"],
            data: [],
          },
        ],
      },
      // ********************接收定时器************************
      datar: null, // 数据定时器    // 动态数据
      // ********************定时器参数************************
      set_page_updataTime: 60, // 页面刷新时间 单位秒
      switch_state: null, // 定时器请求开关
    };
  },
  mixins: [echartMixins],
  created() {
    this.initData();
    this.clock_open(); // 创建时加载时钟动态的数据
  },
  methods: {
    getOvet() {
      var _this = this;
      if (this.switch_state) {
        this.switch_state = false;
        getVpdn()
          .then((response) => {
            let arr = [];
            let vpdn = [];
            Object.keys(response.data.data).forEach(function (key) {
              var multiple = response.data.data[key];

              arr.push({ name: multiple.name, value: multiple.value });

              var single = response.data.data[key].name;
              vpdn.push(single);
            });

            // _this.num = 1861;
            // _this.zncj = response.data.data.value[1]
            // console.log(response.data.data[1].value)
            // response.data.data[0].value+response.data.data[1].value+response.data.data[2].value
            // console.log(response.data.data[2].value)

            _this.mouse.color = ["#37a2da", "#ffdb5c", "#e7bcf3", "#8378ea"];
            _this.mouse.legend.data = vpdn;
            _this.mouse.series[0].data = arr;

            this.$forceUpdate(); // 因为数据层次太多，render函数没有自动更新，需手动强制刷新。
            this.switch_state = true; // 完成后解锁

            this.chart = this.$echarts.init(
              document.getElementById("centreLeft1Chart")
            );
            this.chart.setOption(_this.mouse);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // --------------------时钟开关--------------------
    clock_open() {
      var self = this; // 动态数据的定时器
      this.datar = setTimeout(
        // 内部定义当前函数  执行需求的函数 与  延时调用的定义当前函数
        function loopQuery() {
          self.getOvet();
          self.datar = setTimeout(loopQuery, self.set_page_updataTime * 1000);
        },
        100 // 0.1秒后执行 延时器
      );
    },
    clock_close() {
      clearTimeout(this.datar);
      this.datar = null; // 数据  清除
    },
    // 总数据方法 ---- 创建数据
    initData() {
      this.switch_state = true; // 开启请求
      this.getOvet();
    },
  },
  beforeDestroy() {
    // 死亡前调用
    this.clock_close();
  },
  destroyed() {
    window.onresize = null;
    this.clock_close();
  },
};
</script>

<style lang="scss" scoped>
</style>