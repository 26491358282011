import axios from 'axios'
// import qs from 'qs'
// let url_initial = 'http://172.25.16.107:8000'


let url_initial = 'https://www.pgnice.com/'
// let url_initial = 'http://172.25.16.75:8000'


// 各集团接入电厂统计
export const getOrders = params => {
    return axios.get(`${url_initial}/powercount/`,{params:params})
};

// 任务排行榜
export const getTest = params => {
    return axios.get(`${url_initial}/powercounton/`,{params:params})
};


// 四大类型
export const getType = params => {
    return axios.get(`${url_initial}/ptypecount/`,{params:params})
};

// 地图经纬度
export const getMap = params => {
    return axios.get(`${url_initial}/location/`,{params:params})
};