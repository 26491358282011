<template>
  <div>
    <!-- <div id="main" style="width: 1300px; height: 950px;"></div> -->
    <div id="main" style="width: 16rem; height: 12rem"></div>
    <!-- width: 1300px; height: 950px -->
    <!-- style="width:11.25rem;height:6.25rem;" -->
  </div>
</template>

<script>
import { getMap } from "../api/api";
import "echarts/map/js/china.js";
import echartMixins from "@/utils/resizeMixins";
export default {
  data() {
    return {
      myChart: null,
      active: "",
      geoFengMap: [],
      geoGuangMap: [],
      geoShuiMap: [],
      geoHuoMap: [],
      rows: [],
      geoCount: [],
      colllr: [],

      one: 1, //流入流出控制
      two: 2,
      three: 3,
      four: 4,
      five: 5,
      geoGpsMap: [121.4648, 31.2891],
      colors: [["#00008B", "#6FD52B", "#FFCC00", "#00bfff", "#F96022"]],
      colorIndex: 0,
      year: ["总电站", "风", "光", "水", "火"],
      mapData: [[], [], [], [], []],
      categoryData: [], // 柱子
      barData: [],
      listdata:[],

      optionXyMap01: {
        timeline: {
          data: ["总电站", "风", "光", "水", "火"], // year
          axisType: "category",
          autoPlay: true,
          playInterval: 16000,
          left: "10%",
          right: "10%",
          bottom: "1%",
          width: "80%",
          symbolSize: 10,
          lineStyle: {
            color: "#555",
          },
          controlStyle: {
            showNextBtn: true,
            showPrevBtn: true,
            normal: {
              color: "#666",
              borderColor: "#666",
            },
            emphasis: {
              color: "#aaa",
              borderColor: "#aaa",
            },
          },
        },
        baseOption: {
          animation: true,
          animationDuration: 1000,
          animationEasing: "cubicInOut",
          animationDurationUpdate: 1000,
          animationEasingUpdate: "cubicInOut",
          grid: {
            right: "10%",
            // top: "15%",
            bottom: "5%",
            width: "80%",
            height: "20%",
          },
          tooltip: {
            trigger: "axis", // hover触发器
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
              shadowStyle: {
                color: "rgba(150,150,150,0.1)", //hover颜色
              },
            },
          },
          geo: {
            show: true,
            map: "china",
            roam: true,
            zoom: 1.1,
            center: [104.090654, 35.143231], // 四川内江市
            label: {
              show: true, // 新增加
              color: "#FFFACD",
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                borderColor: "rgba(147, 235, 248, 1)",
                borderWidth: 1,
                areaColor: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
                shadowColor: "rgba(128, 217, 248, 1)",
                // shadowColor: 'rgba(255, 255, 255, 1)',
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                shadowBlur: 10,
              },
              emphasis: {
                areaColor: "#389BB7",
                borderWidth: 0,
              },
            },
          },
        },
        options: [],
      },
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.draw();
  },
  methods: {
    draw() {
      var _this = this;
      getMap()
        .then((response) => {
          this.geoFengMap = response.data.data.feng;
          this.geoGuangMap = response.data.data.guang;
          this.geoShuiMap = response.data.data.shui;
          this.geoHuoMap = response.data.data.huo;

          var _rows = [];
          _rows.push(
            this.geoFengMap,
            this.geoGuangMap,
            this.geoShuiMap,
            this.geoHuoMap
          );
          this.geoCount = _rows;

          let geoCoordMap = this.geoFengMap;
          let geoCoordMap2 = this.geoGuangMap;
          let geoCoordMap3 = this.geoShuiMap;
          let geoCoordMap4 = this.geoHuoMap;
          let geoCountMap = this.geoCount;
          
    
        //   let points = [
        //           {value: [118.8062, 31.9208],itemStyle:{color:'#4ab2e5'}}
        //           , {value: [119.4543, 25.9222]}]
        
          

          Object.keys(geoCountMap).forEach(function (key) {
            // 取名字
            var uiki = geoCountMap[key];
            for (var key5 in uiki) {
              Object.keys(uiki).forEach(function (key) {
                // 取颜色
                _this.rrr = uiki[key][3].color;
                // console.log(rrr[3].color)
              });
              _this.mapData[0].push({
                year: "总电站",
                name: key5,
                value: 50,
                // Style: _this.rrr,
                itemStyle: { color: _this.rrr },
              });
              // console.log(_this.rrr)
            }
          });
          // _+_+_+_+_++_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+_
          // 打印五项
          //   console.log(mapData);
          // _+_+_+_+_++_+_+_+_+_+_+_+_+_+_+_+_+_+_+_+

          for (var key in geoCoordMap) {
            _this.mapData[1].push({
              year: "风",
              name: key,
              value: 50,
              //   value1: geoCoordMap[key][3],
              //   itemStyle : geoCoordMap[key][3]["itemStyle"]
              itemStyle: { color: "#6FD52B" },
              //   Style: "#6FD52B",
            });
            // console.log(geoCoordMap)
            // console.log(mapData[1])
          }

          for (var key2 in geoCoordMap2) {
            _this.mapData[2].push({
              year: "光",
              name: key2,
              value: 50,
              //   value1: geoCoordMap2[key2][3],
              //   Style: "#FFCC00",
              itemStyle: { color: "#FFCC00" },
            });
          }

          for (var key3 in geoCoordMap3) {
            _this.mapData[3].push({
              year: "水",
              name: key3,
              value: 50,
              //   value1: geoCoordMap3[key3][3],
              //   Style: "#00bfff",
              itemStyle: { color: "#00bfff" },
            });
          }

          for (var key4 in geoCoordMap4) {
            _this.mapData[4].push({
              year: "火",
              name: key4,
              value: 50,
              //   value1: geoCoordMap4[key4][3],
              //   Style: "#F96022",
              itemStyle: { color: "#00bfff" },
            });
          }

          for (var i = 0; i < _this.mapData.length; i++) {
            _this.mapData[i].sort(function sortNumber(a, b) {
              return a.value - b.value;
            });
            _this.barData.push([]);
            _this.categoryData.push([]);
            for (var j = 0; j < _this.mapData[i].length; j++) {
              _this.barData[i].push(_this.mapData[i][j].value1);
              _this.categoryData[i].push(_this.mapData[i][j].name);
            }
          }

          let convertData = function (data) {
            // console.log(data)
            var res = [];
            for (var i = 0; i < data.length; i++) {
              var geoCoordcount = geoCountMap[data[i].name];
              var geoCoord = geoCoordMap[data[i].name];
              var geoCoord2 = geoCoordMap2[data[i].name];
              var geoCoord3 = geoCoordMap3[data[i].name];
              var geoCoord4 = geoCoordMap4[data[i].name];
            //   console.log(geoCountMap[data[i].name])

              if (geoCoordcount) {
                res.push({
                  name: data[i].name,
                  value: geoCoordcount.concat(data[i].value),
                });
                console.log(geoCoordcount[3].color)
              }

              if (geoCoord) {
                res.push({
                  name: data[i].name,
                  value: geoCoord.concat(data[i].value),
                  itemStyle:{ color: geoCoord[3].color}
                });
              }
              if (geoCoord2) {
                res.push({
                  name: data[i].name,
                  value: geoCoord2.concat(data[i].value),
                  itemStyle:{ color: geoCoord2[3].color}
                });
              }
              if (geoCoord3) {
                res.push({
                  name: data[i].name,
                  value: geoCoord3.concat(data[i].value),
                  itemStyle:{ color: geoCoord3[3].color}
                });
              }
              if (geoCoord4) {
                res.push({
                  name: data[i].name,
                  value: geoCoord4.concat(data[i].value),
                  itemStyle:{ color: geoCoord4[3].color}
                });
              }
            }
            // console.log(res)
            return res;
          };
        //   console.log(convertData);

          let convertToLineData = function (data) {
            var res = [];
            for (var i = 0; i < data.length; i++) {
              var dataItem = data[i];
              var toCoord5 = geoCountMap[dataItem.name];
              var toCoord = geoCoordMap[dataItem.name];
              var toCoord2 = geoCoordMap2[dataItem.name];
              var toCoord3 = geoCoordMap3[dataItem.name];
              var toCoord4 = geoCoordMap4[dataItem.name];

              var fromCoord = [116.418757, 39.917544];
              //  var toCoord = geoGps[Math.random()*3];
              if (fromCoord && toCoord5) {
                if (_this.one == 1) {
                  res.push([
                    {
                      coord: toCoord5,
                    },
                    {
                      coord: fromCoord,
                      value: dataItem.value,
                    },
                  ]);
                }
              }
              if (fromCoord && toCoord) {
                if (_this.two == 2) {
                  res.push([
                    {
                      coord: toCoord,
                    },
                    {
                      coord: fromCoord,
                      value: dataItem.value,
                    },
                  ]);
                }
              }
              if (fromCoord && toCoord2) {
                if (_this.three == 3) {
                  res.push([
                    {
                      coord: toCoord2,
                    },
                    {
                      coord: fromCoord,
                      value: dataItem.value,
                    },
                  ]);
                }
              }
              if (fromCoord && toCoord3) {
                if (_this.four == 4) {
                  res.push([
                    {
                      coord: toCoord3,
                    },
                    {
                      coord: fromCoord,
                      value: dataItem.value,
                    },
                  ]);
                }
              }
              if (fromCoord && toCoord4) {
                if (_this.five == 5) {
                  res.push([
                    {
                      coord: toCoord4,
                    },
                    {
                      coord: fromCoord,
                      value: dataItem.value,
                    },
                  ]);
                }
              }
            }
            return res;
          };
        //   console.log(convertToLineData);

          // ==========================================================================
          // options里面内容
          for (var n = 0; n < _this.year.length; n++) {
            // 打印五项
            //   console.log(this.mapData[n]);
            // console.log(convertData(_this.mapData[n]))
            // console.log(_this.mapData);
            // console.log(convertData(_this.mapData[n]))
            
            
              

            _this.optionXyMap01.options.push({
              backgroundColor: "rgba(0,0,0,0.2)",
              timeline: {
                label: {
                  // 按钮底下文字颜色
                  normal: {
                    textStyle: {
                      color: "#ddd",
                    },
                  },
                  emphasis: {
                    textStyle: {
                      color: "#fff",
                    },
                  },
                },
                checkpointStyle: {
                  // 地图按钮颜色
                  // =============================================
                  //   color: [], //colors[colorIndex][n]
                  color: _this.colors[_this.colorIndex][n],
                  // =============================================
                  borderColor: "#777",
                  borderWidth: 2,
                },
              },

              xAxis: {
                show: false, // 不显示文字
                type: "category",
                scale: true,
                position: "top",
                min: 0,
                boundaryGap: false,

                splitLine: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  margin: 2,
                  textStyle: {
                    color: "#aaa",
                  },
                },
              },
              yAxis: {
                show: false, // 不显示文字
                type: "value",
                splitLine: { show: false },
                // nameGap: 16,
                axisLine: {
                  lineStyle: {
                    color: "#B4B4B4",
                  },
                },
                axisTick: {
                  show: false,
                  lineStyle: {
                    color: "#ddd",
                  },
                },
                axisLabel: {
                  interval: 0,
                  textStyle: {
                    color: "#ddd",
                  },
                },

                data: [], //categoryData[n]
              },

              series: [
                //未知作用
                {
                  //文字和标志
                  name: "light",

                  type: "scatter",
                  coordinateSystem: "geo",

                  zlevel: 2, // 新增加
                    // data: points, //convertData(mapData[n])
                  data: convertData(_this.mapData[n]), //convertData(mapData[n])
                //   data: [{value: [118.8062, 31.9208],itemStyle:{color:'#4ab2e5'}}],
                //   symbolSize: function (val) {
                //     return val[2] / 10;
                //   },
                  label: {
                    normal: {
                      formatter: "{b}",
                      position: "right",
                      show: false, //有改动
                    },
                    emphasis: {
                      show: true,
                    },
                  },
                  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                  // 地图点的颜色
                  itemStyle: {
                    normal: {
                      // color: colors[colorIndex][n],
                    },
                  },

                  //   地图点的颜色
                  //   itemStyle:{  normal:{color:[] } }
                  //   itemStyle:{  normal:{color:_this.colorStyles.Style } }

                  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                },
                //地图？
                {
                  type: "map",
                  map: "china",
                  geoIndex: 0,
                  aspectScale: 0.75, //长宽比
                  showLegendSymbol: false, // 存在legend时显示
                  label: {
                    normal: {
                      show: true,
                    },
                    emphasis: {
                      show: false,
                      textStyle: {
                        color: "#fff",
                      },
                    },
                  },
                  roam: true,
                  itemStyle: {
                    normal: {
                      areaColor: "#031525",
                      borderColor: "#FFFFFF",
                    },
                    emphasis: {
                      areaColor: "#2B91B7",
                    },
                  },
                  animation: false,

                //   data: [], //mapData
                  data: _this.mapData

                },

                //地图线的动画效果
                {
                  type: "lines",
                  //   type: "effectScatter",
                  zlevel: 5,
                  effect: {
                    show: true,
                    period: 7.83, //箭头指向速度，值越小速度越快
                    trailLength: 0.7, //特效尾迹长度[0,1]值越大，尾迹越长重
                    // symbol: 'circle', //箭头图标
                    color: "#fff",
                    symbolSize: 2, //图标大小  修改
                  },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 0,
                      curveness: 0.2, // 边的曲度，取值0~1，值越大曲度越大
                    },
                    emphasis: {
                      width: 2,
                      opacity: 0.5,
                    },
                  },
                //   data: [], //convertToLineData(mapData[n], geoGpsMap)
                  data: convertToLineData(_this.mapData[n], _this.geoGpsMap), //convertToLineData(mapData[n], geoGpsMap)

                },
                // ==============================================================
                //被攻击点
                {
                  type: "scatter",
                  coordinateSystem: "geo",
                  zlevel: 2,
                  rippleEffect: {
                    period: 4,
                    brushType: "stroke",
                    scale: 4,
                  },
                  label: {
                    normal: {
                      show: true,
                      position: "right",
                      color: "#DC143C",
                      formatter: "{b}",
                      textStyle: {
                        color: "#DC143C",
                      },
                    },
                    emphasis: {
                      show: true,
                    },
                  },
                  symbol: "pin",
                  symbolSize: 50,
                  itemStyle: {
                    normal: {
                      show: true,
                      color: "#DC143C",
                    },
                  },
                  data: [{
                    name: "北京",
                    value:[116.418757, 39.917544],
                }],
                },
                
                // =================================================================
              ],
            });
            // Object.keys(_this.mapData[n]).forEach(function (key) {
            //       _this.colorStyles = _this.mapData[n][key];
            //       _this.optionXyMap01.options[n].series[0].itemStyle.normal.color.push(_this.colorStyles.Style)
            //     });
          }

          //             // 地图下的 按钮颜色
          //               _this.optionXyMap01.options[0].timeline.checkpointStyle.color = _this.colors[_this.colorIndex][n]
          //             // 柱状图的东西 暂时没啥用
          //               _this.optionXyMap01.options[0].yAxis.data = _this.categoryData[n]
          // // ++++++++++++++++++++++++++++++++ Map ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
          //             // 地图
          //               _this.optionXyMap01.options[0].series[0].data = convertData(_this.mapData[n])
          //             // 地图点的颜色
          // Object.keys(_this.mapData[n]).forEach(function (key) {
          //   _this.colorStyles = _this.mapData[n][key];
          //   _this.optionXyMap01.options[0].series[0].itemStyle.normal.color = _this.colorStyles.Style
          // });

          //               _this.optionXyMap01.options[0].series[1].data = _this.mapData
          //               _this.optionXyMap01.options[0].series[2].data = convertToLineData(_this.mapData[n], this.geoGpsMap)
          // // ++++++++++++++++++++++++++++++++ Map +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

          // ==========================================================================
          // 基于准备好的dom，初始化echarts实例
          this.myChart = this.$echarts.init(document.getElementById("main"));
          this.myChart.setOption(_this.optionXyMap01);
          console.log(_this.optionXyMap01);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  destroyed() {
    window.onresize = null;
  },
  //   beforeDestroy() {
  //     this.myChart.clear();
  //   },
};
</script>

<style lang="scss" scoped>
</style>