<template>
  <div id="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-pie"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">各集团电厂分类统计</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="d-flex jc-center">
        <centreLeft1Chart />
        <div
          style="
            margin-left: 94px;
            margin-top: -40px;
            z-index: 99999999;
            position: absolute;
            width: 200px;
            height: 50px;
          "
        >
          <span class="coina"
            >中能承建:<span class="aaa"><countTo :startVal='startVal' :endVal='this.zncj' :duration='5000'></countTo></span></span
          >
        </div>
        <div
          style="
            margin-left: 94px;
            margin-top: -40px;
            z-index: 99999999;
            position: absolute;
            width: 200px;
            height: 50px;
          "
        >
          <span class="coinb"
            >数据对接:<span class="bbb"><countTo :startVal='startVal' :endVal='this.sjdj' :duration='5000'></countTo></span></span
          >
        </div>
        <div
          style="
            margin-left: 94px;
            margin-top: -40px;
            z-index: 99999999;
            position: absolute;
            width: 200px;
            height: 50px;
          "
        >
          <span class="coin"
            >电厂总数:<span class="ccc"><countTo :startVal='startVal' :endVal='this.dczs' :duration='5000'></countTo></span></span
          >
        </div>
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div
          class="item-box"
          v-for="(item, index) in numberData"
          :key="index"
          style="top: 40px; left: 20px"
        >
          <div class="d-flex">
            <span class="coin">{{ item.text }}:</span>
            <dv-digital-flop
              :config="item.number"
              style="width: 2.5rem; height: 0.625rem"
            ></dv-digital-flop>
          </div>
          <!-- <p class="text" style="text-align: center;font-weight: bold;">
            {{ item.text }} -->
          <!-- <span class="colorYellow">(件)</span> -->
          <!-- </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getType } from "../api/api";
import centreLeft1Chart from "@/components/echart/centerLeft2/centreLeft1Chart";
import countTo from 'vue-count-to';
export default {
  data() {
    return {
      startVal: 0,
      num: [],
      wal: [],
      // 中能承建
      zncj: 0,
      // 数据对接
      sjdj: 0,
      // 电厂总数
      dczs: 0,
      config: {
        lineWidth: 30,
        activeRadius: "80%",
        radius: "75%",
        activeTimeGap: 2000,
      },
      numberData: [
        // {
        // number: {
        //   number: "",
        //   // toFixed: 1,
        //   // content: "{nt}"
        // },
        // text: ""
        // },
        // {
        //   number: {
        //     number: [1144],
        //     toFixed: 1,
        //     content: "{nt}"
        //   },
        //   text: "总共完成数量"
        // },
        // {
        //   number: {
        //     number: [361],
        //     toFixed: 1,
        //     content: "{nt}"
        //   },
        //   text: "正在编译数量"
        // },
        // {
        //   number: {
        //     number: '',
        //     toFixed: 1,
        //     content: "{nt}"
        //   },
        //   text: ""
        // }
      ],
      // ********************接收定时器************************
      datar: null, // 数据定时器    // 动态数据
      // ********************定时器参数************************
      set_page_updataTime: 60, // 页面刷新时间 单位秒
      switch_state: null, // 定时器请求开关
    };
  },
  components: {
    centreLeft1Chart,
    countTo
  },
  mounted() {
    // this.changeTiming();
    // if(this.timer){
    //     clearInterval(this.timer)
    // }else{
    //     this.timer=setInterval(()=>{
    //         //获取数据
    //         this.getTTp()
    //     },1000)
    // }
  },
  created() {
    this.initData();
    this.clock_open(); // 创建时加载时钟动态的数据
  },
  methods: {
    // changeTiming() {
    //   setInterval(() => {
    //     this.changeNumber();
    //   }, 3000);
    // },
    // changeNumber() {
    //   this.numberData.forEach((item, index) => {
    //     item.number.number[0] += ++index;
    //     item.number = { ...item.number };
    //     console.log(item.number.number[0])
    //   });
    // },
    getTTp() {
      var _this = this;
      if (this.switch_state) {
        this.switch_state = false;
        getType()
          .then((response) => {
            // 中能承建
            this.zncj =
              response.data.data[0].value +
              response.data.data[1].value +
              response.data.data[2].value +
              response.data.data[3].value;
            // 数据对接
            this.sjdj = response.data.data[4].value;
            // 电厂总数
            this.dczs = this.zncj + this.sjdj;
            let arr = [];
            // this.zncj = response.data.data[1].value + response.data.data[2].value + response.data.data[3].value + response.data.data[4].value
            Object.keys(response.data.data).forEach(function (key) {
              _this.wal = response.data.data[key].name;
              _this.num = response.data.data[key].value;
              // this.switch_state = true; // 完成后解锁
              arr.push({
                number: {
                  number: [_this.num],
                  // toFixed: 1,
                  content: "{nt}",
                  // animationFrame:100
                },
                text: _this.wal,
              });
            });
            // 删除 最后一个数据对接
            arr.pop()
            _this.numberData = arr;
            this.$forceUpdate(); // 因为数据层次太多，render函数没有自动更新，需手动强制刷新。
            this.switch_state = true; // 完成后解锁
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // --------------------时钟开关--------------------
    clock_open() {
      var self = this; // 动态数据的定时器
      this.datar = setTimeout(
        // 内部定义当前函数  执行需求的函数 与  延时调用的定义当前函数
        function loopQuery() {
          self.getTTp();
          self.datar = setTimeout(loopQuery, self.set_page_updataTime * 1000);
        },
        100 // 0.1秒后执行 延时器
      );
    },
    clock_close() {
      clearTimeout(this.datar);
      this.datar = null; // 数据  清除
    },
    // 总数据方法 ---- 创建数据
    initData() {
      this.switch_state = true; // 开启请求
      this.getTTp();
    },
  },
  beforeDestroy() {
    // 死亡前调用
    this.clock_close();
  },
  destroyed() {
    this.clock_close();
  }
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 0.2rem;
  // height: 5.125rem;
  height: 6.5rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    // height: 4.8125rem;
    height: 6.1rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }

  .coin {
    position: absolute;
    left: -0.27rem;
    top: 4.4125rem;
    font-size: 0.25rem;
    color: #ffc107;
  }
  .ccc {
    position: absolute;
    left: 1.2rem;
    top: 0rem;
    font-size: 0.25rem;
    color: red;
  }

  .coina {
    position: absolute;
    left: -0.27rem;
    top: 3.4125rem;
    font-size: 0.25rem;
    color: #ffc107;
  }
  .aaa {
    position: absolute;
    left: 1.2rem;
    top: 0rem;
    font-size: 0.25rem;
    color: red;
  }

  .coinb {
    position: absolute;
    left: -0.27rem;
    top: 3.9125rem;
    font-size: 0.25rem;
    color: #ffc107;
  }
  .bbb {
    position: absolute;
    left: 1.2rem;
    top: 0rem;
    font-size: 0.25rem;
    color: red;
  }
}
</style>