<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="align-left" style="margin-top: 10px"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2" style="margin-top: 10px"
            >各集团电厂在线率</span
          >
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board
          ref="scrollBoard"
          :config="config"
          style="width: 3.55rem; height: 4.28rem"
        >
        </dv-scroll-board>
      </div>
    </div>
  </div>
</template>

<script>
import { getTest } from "../api/api";
export default {
  data() {
    return {
      adb: [],
      config: {
        // header: ["组件", "分支", "覆盖率"],
        data: [
          // ["组件1", "dev-1", "1"],
          // ["组件2", "dev-2", "<span  class='colorRed'>↓33%</span>"],
          // ["组件3", "dev-3", "<span  class='colorGrass'>↑100%</span>"],
          // ["组件4", "rea-1", "<span  class='colorGrass'>↑94%</span>"],
          // ["组件5", "rea-2", "<span  class='colorGrass'>↑95%</span>"],
          // ["组件6", "fix-2", "<span  class='colorGrass'>↑63%</span>"],
          // ["组件7", "fix-4", "<span  class='colorGrass'>↑84%</span>"],
          // ["组件8", "fix-7", "<span  class='colorRed'>↓46%</span>"],
          // ["组件9", "dev-2", "<span  class='colorRed'>↓13%</span>"],
          // ["组件10", "dev-9", "<span  class='colorGrass'>↑76%</span>"],
        ],
        rowNum: 8, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [50],
        align: ["center"],
        indexHeader: "No",
      },
      // ********************接收定时器************************
      timedate: null, // 数据定时器    // 动态数据
      // ********************定时器参数************************
      set_page_updataTime: 60, // 页面刷新时间 单位秒
      switch_state: null, // 定时器请求开关
    };
  },
  methods: {
    getRenwu() {
      var _this = this;
      // getTest()
      //   .then((response) => {

      //     var _rows = []

      //     Object.keys(response.data.data).forEach(function (key) {

      //       var _items = {};

      //       let _name = response.data.data[key].company_name
      //       let _idx = key*1+1
      //       let _ceils = [_idx,_name,'rows',"<span  class='colorRed'>↓33%</span>"]

      //       _items.ceils = _ceils
      //       _items.rowIndex = key
      //       _items.scroll = key

      //       _rows.push(_items)

      //     });

      //     _this.config = {
      //         header: ["No","组件","分支","覆盖"],
      //         rowNum: 8, //表格行数
      //         headerHeight: 35,
      //         headerBGC: "#0f1325", //表头
      //         oddRowBGC: "#0f1325", //奇数行
      //         evenRowBGC: "#171c33", //偶数行
      //         // index: true,
      //         columnWidth: [50],
      //         align: ["center"],
      //         indexHeader: "No",
      //         hoverPause:true}

      //     _this.config.data = _rows
      //     // _this.$refs["scrollBoard"]._data.rows = _rows

      //     // console.log(_this.$refs["scrollBoard"]._data.rows)

      //   })
      if (this.switch_state) {
        this.switch_state = false;
        getTest()
          .then((response) => {
            var _rows = [];

            Object.keys(response.data.data).forEach(function (key) {
              var _items = {};
              // console.log(response.data.data[key].gname)
              let _name = response.data.data[key].gname;
              let _idx = key * 1 + 1;
              let _count = response.data.data[key].count;
              // // let _ceils = [_idx,_name,'rows',"<span  class='colorRed'>↓33%</span>"]

              // _items.index = "&nbsp;&nbsp&nbsp;" + _idx;
              // // _items.scroll =  ("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"+_name)
              // _items.scroll = _name;
              // _items.rowIndex = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + _count;
              // // _items.ceils = _ceils
              // if (response.data.data[key].onlinerate <= "20") {
              //   _items.ceils =
              //     "&nbsp;&nbsp<span  class='colorRed'>" +
              //     response.data.data[key].onlinerate +
              //     "</span>";
              // }
              // if (
              //   response.data.data[key].onlinerate > "20" ||
              //   response.data.data[key].onlinerate < "101"
              // ) {
              //   _items.ceils =
              //     "&nbsp;&nbsp<span  class='colorGrass'>" +
              //     response.data.data[key].onlinerate +
              //     "</span>";
              // }
              // _rows.push(_items);

              _items.index =  ("&thinsp;&thinsp;&thinsp;"+_idx);
              // _items.scroll =  ("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"+_name)
              _items.scroll = _name;
              _items.rowIndex =  ("&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;"+_count);
              // _items.ceils = _ceils
              if (response.data.data[key].onlinerate <= "20") {
                _items.ceils =
                  "&thinsp;&thinsp;&thinsp;<span  class='colorRed'>" +
                  response.data.data[key].onlinerate +
                  "</span>";
              }
              if (
                response.data.data[key].onlinerate > "20" ||
                response.data.data[key].onlinerate < "101"
              ) {
                _items.ceils =
                  "&thinsp;&thinsp;&thinsp;<span  class='colorGrass'>" +
                  response.data.data[key].onlinerate +
                  "</span>";
              }
              _rows.push(_items);
            });

            _this.config = {
              // header: ["No","&nbsp;电厂","&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;总数","&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过"],
              // header: [
              //   "&nbsp;&nbspNo",
              //   "&nbsp;&nbsp;&nbsp;&nbsp;电厂",
              //   "&nbsp;&nbsp;&nbsp;在线数",
              //   "&nbsp;在线率",
              // ],
              header: [
                "No",
                "&thinsp;&thinsp;&thinsp;集团",
                "在线数",
                "在线率",
              ],
              widths: (100, 100, 100, 100),
              rowNum: 8, //表格行数
              headerHeight: 35,
              headerBGC: "#0f1325", //表头
              oddRowBGC: "#0f1325", //奇数行
              evenRowBGC: "#171c33", //偶数行
              // index: true,
              columnWidth: [50],
              align: ["center"],
              indexHeader: "No",
              hoverPause: true,
              waitTime: 4000,
            };

            _this.config.data = _rows;
            _this.$refs["scrollBoard"]._data.rows = _rows;

            // console.log(_this.$refs["scrollBoard"]._data)
            this.switch_state = true; // 完成后解锁
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // // --------------------时钟开关--------------------
    blsk_open() {
      var self = this; // 动态数据的定时器
      this.timedate = setTimeout(
        // 内部定义当前函数  执行需求的函数 与  延时调用的定义当前函数
        function loopQuery() {
          self.getRenwu();
          self.timedate = setTimeout(
            loopQuery,
            self.set_page_updataTime * 1000
          );
        },
        100 // 0.1秒后执行 延时器
      );
    },
    clock_close() {
      clearTimeout(this.timedate);
      this.timedate = null; // 数据  清除
    },
    // 总数据方法 ---- 创建数据
    bnitData() {
      this.switch_state = true; // 开启请求
      this.getRenwu();
    },
  },
  created() {
    // this.getRenwu();
    this.bnitData();
    this.blsk_open(); // 创建时加载时钟动态的数据
  },
  beforeDestroy() {
    // 死亡前调用
    this.clock_close();
  },
  destroyed() {
    // 死亡后调用
    this.clock_close();
  },
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.15rem;
  height: 5.225rem;
  width: 3.9rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    width: 3.55rem;
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
  .header .header-item:nth-child(1),
  .rows .row-item .ceil:nth-child(1) {
    width: 16.5% !important;
  }
  .header .header-item:nth-child(2),
  .rows .row-item .ceil:nth-child(2) {
    width: 37.5% !important;
  }
  .header .header-item:nth-child(3),
  .rows .row-item .ceil:nth-child(3) {
    width: 23% !important;
  }
  .header .header-item:nth-child(4),
  .rows .row-item .ceil:nth-child(4) {
    width: 23% !important;
  }
}
// .ceil{
//     // height: 60px;
//     line-height: 30px;
//     width: 65px;
//     overflow : hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box;
//     // -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//   }
</style>