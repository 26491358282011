<template>
  <div id="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">VPDN监控</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="d-flex jc-center">
        <bottomBobchart />
      </div>
    </div>
  </div>
</template>

<script>
import bottomBobchart from "@/components/echart/bottom/bottomBobchartnew";
// import bottomBobchart from "@/components/echart/bottom/rro";
export default {
  data() {
    return {
      
    };
  },
  components: {
    bottomBobchart,
  },
  // mounted() {
  //   this.changeTiming();
  // },
  created() {
    
  },
  methods: {
    
  },
 
  destroyed() {
   
  },
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 0.2rem;
  // height: 5.125rem;
  height: 6.5rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    // height: 4.8125rem;
    height: 6.1rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>