<template>
  <div id="center">
    <div class="down">
      <div class="ranking bg-color-black">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1">各集团接入电厂统计</span>
        <div class="d-flex jc-center body-box">
          <dv-scroll-ranking-board ref="scrollBoard" :config="ranking" style="width:3.1rem;height:4.2rem" />
        </div>
        <!-- <div class="dv-scroll-ranking-board" style="width: 2.8rem; height: 4.6rem;">
          <div v-for="(item,index) in adb" :key="index" class="row-item" style="height: 87.2px;">
            <div class="ranking-info" >
              <div class="rank">{{ item.power_id }}</div>
              <div class="info-name">{{ item.company_name }}</div>
              <div class="ranking-value">{{ item.operation_channelnum }}</div>
            </div>
            <div class="ranking-column">
              <div class="inside-column" style="width: 100%;">
                <div class="shine"></div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getOrders } from "../api/api";
export default {
  data() {
    return {
      adb: [],
      ranking: {
        // data: [],
        data: [
          // {
          //   name: "苏州",
          //   value: 78
          // },
          // {
          //   name: "昆明",
          //   value: 66
          // },
          
        ],
        // waitTime: 4000,
        // // carousel: "page",
        // unit: "份",
        // rowNum: 8, // 显示条数
        // align: "center",
      },
      // ********************接收定时器************************
      datar: null,            // 数据定时器    // 动态数据
      // ********************定时器参数************************
      set_page_updataTime:60,  // 页面刷新时间 单位秒
      switch_state:null,      // 定时器请求开关
    };
  },
  created() {
    // this.getdaTa()
    this.initData();
    this.clock_open();  // 创建时加载时钟动态的数据
  },
  methods: {
    getdaTa() {
      var _this = this;
      if (this.switch_state) {
        this.switch_state = false;
        getOrders()
          .then((response) => {
            // console.log(response.data.data)


            let arr = []

            Object.keys(response.data.data).forEach(function (key) {
            
              var adb = response.data.data[key];
              // console.log(response.data.data[key])
              
              arr.push({name: adb.gname,
                    value: adb.count})            
              
            });
            
            // 在这翻页

            // 进行排序
          _this.ranking = {waitTime: 4000,unit: "个",rowNum: 8, // 显示条数
          align: "center"}
          _this.ranking.data = arr
          _this.$refs['scrollBoard']._data.rows = arr

          // console.log(_this.ranking.data)
          //  console.log(_this.$refs['scrollBoard']._data.rows)

          // 模板语言方法
          // this.adb = response.data.data
          
          this.$forceUpdate(); // 因为数据层次太多，render函数没有自动更新，需手动强制刷新。
          this.switch_state = true; // 完成后解锁

          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // --------------------时钟开关--------------------
    clock_open() {
      var self = this;         // 动态数据的定时器
      this.datar = setTimeout( // 内部定义当前函数  执行需求的函数 与  延时调用的定义当前函数
          function loopQuery() {
              self.getdaTa();
              self.datar = setTimeout(loopQuery, self.set_page_updataTime* 1000);
          },
          100 // 0.1秒后执行 延时器
      );
    },
    clock_close() {
        clearTimeout( this.datar );
        this.datar = null;  // 数据  清除
    },
    // 总数据方法 ---- 创建数据
    initData(){
        this.switch_state = true;   // 开启请求
        this.getdaTa()
    }
  },
  beforeDestroy(){// 死亡前调用
      this.clock_close()
  },
  destroyed(){    // 死亡后调用
      this.clock_close();
  },
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .down {
    padding: 0.2rem;
    // padding-bottom: 0;
    width: 3.7rem;
    display: flex;
    // height: 5.1rem;
    justify-content: space-between;
    
    height: 5.125rem;
    border-radius: 0.0625rem;
    


    .bg-color-black {
      width: 3.55rem;
      height: 4.8125rem;
      border-radius: 0.125rem;
      // border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 4rem;
    }
  }
}
</style>