<template>
  <div>
    <div id="bottomRightChart" style="width: 3.55rem; height: 5.9rem;top:0.2rem"></div>
  </div>
  <!-- // 实施开始, 实施完成, 实施验收 -->
</template>

<script>
import echartMixins from "@/utils/resizeMixins";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.draw();
    this.charTimg();
  },
  mixins: [echartMixins],
  methods: {
    charTimg() {
      setInterval(() => {
        this.draw();
      }, 6000);
    },
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(
        document.getElementById("bottomRightChart")
      );
      //  ----------------------------------------------------------------
      // 数据
      var dataMap = {};
      function dataFormatter(obj) {
        var pList = [
          "中核集团",
          "中国华能",
          "中国大唐",
          "中国华电",
          "国家电投",
          "国投集团",
          "华润集团",
          "中国电建",
          "中国能建",
          "中广核集团",
          "其他能源集团"
        ];
        var temp;
        for (var year = 2007; year <= 2011; year++) {
          var max = 0;
          var sum = 0;
          temp = obj[year];
          for (var i = 0, l = temp.length; i < l; i++) {
            max = Math.max(max, temp[i]);
            sum += temp[i];
            obj[year][i] = {
              name: pList[i],
              value: temp[i],
            };
          }
          obj[year + "max"] = Math.floor(max / 100) * 100;
          obj[year + "sum"] = sum;
        }
        return obj;
      }

      

      dataMap.dataPI = dataFormatter({
        //max : 4000,
        2011: [
          28,
          12,
          40,
          25,
          162,
          13,
          51,
          14.,
          6,
          6,
          122,
        ],
        2010: [
          28,
          12,
          40,
          25,
          151,
          13,
          51,
          14.,
          6,
          6,
          104,
        ],
        2009: [
          28,
          12,
          40,
          25,
          137,
          13,
          51,
          14.,
          6,
          6,
          97,
        ],
        2008: [
          112,
          128,
          209,
          313,
          907,
          132,
          912,
          104,
          118,
          211,
          106,
          141,
          117,
        ],
        2007: [
          106,
          119,
          182,
          317,
          761,
         149,
          987,
          113,
          112,
          226,
          118,
          145,
          114,
        ],
        
       
        
       
        
      });

      dataMap.dataSI = dataFormatter({
        //max : 26600,
        2011: [
          23,
          26,
          16,
          11,
          164,
          // 0,
          // 0,
          10,
          40,
          3,
          4,
          3,
          114,
        ],
        2010: [
          23,
          26,
          16,
          11,
          147,
          // 0,
          // 0,
          10,
          40,
          3,
          4,
          3,
          103,
        ],
        2009: [
          23,
          26,
          16,
          11,
          135,
          // 0,
          // 0,
          10,
          40,
          3,
          4,
          3,
          82,
        ],
        2008: [
          241,
          378,
          834,
          436,
          419,
          784,
          312,
          475,
          684,
          134,
          142,
          493,
          544,
        ],
        2007: [
         106,
          110,
          180,
          311,
          762,
          113,
          783,
          915,
          101,
          181,
          986,
          120,
          102,
        ],
        
        
        
     
        
      });

      dataMap.dataTI = dataFormatter({
        //max : 25000,
        2011: [
          17,
          25,
          34,
          24,
          137,
          // 0,
          // 0,
          8,
          32,
          1,
          5,
          5,
          127,
        ],
        2010: [
          16,
          22,
          33,
          22,
          119,
          // 0,
          // 0,
          8,
          30,
          1,
          5,
          4,
          117,
        ],
        2009: [
          13,
          21,
          32,
          21,
          107,
          // 0,
          // 0,
          7,
          28,
          1,
          4,
          3,
          107,
        ],
        2008: [
          837,
          288,
          527,
          275,
          321,
          520,
          241,
          290,
          787,
          118,
          879,
          323,
          434,
        ],
        2007: [
          255,
          384,
          883,
          398,
          514,
          734,
          392,
          472,
          678,
          137,
          149,
          422,
          603,
        ],
       
        
       
        
      });

      

      let option = {
        baseOption: {
          timeline: {
            axisType: "category",
            // realtime: false,
            // loop: false,
            left: "15%",
            autoPlay: true,
            // currentIndex: 2,
            playInterval: 5000,
            // controlStyle: {
            //     position: 'left'
            // },
            data: [
              // "2002-01-01",
              // "2003-01-01",
              // "2004-01-01",
              // {
              //   value: "2020-06-01",
              //   // tooltip: {
              //   //   formatter: "{b} GDP达到一个高度",
              //   // },
              //   symbol: "diamond",
              //   symbolSize: 16,
              // },
              // "2006-01-01",
              // "2007-01-01",
              // "2020-06",
              // "2020-07",
              "2020-08",
              "2020-09",
              "2020-10",
              // {
              //   value: "2020-10-01",
              //   // tooltip: {
              //   //   formatter: function (params) {
              //   //     return params.name + "GDP达到又一个高度";
              //   //   },
              //   // },
              //   symbol: "diamond",
              //   symbolSize: 18,
              // },
            ],
            label: false
            // label: {
            //   formatter: function (s) {
            //     return new Date(s).getFullYear();
            //   },
            // },
          },
          title: {
            // subtext: "数据来自国家统计局",
          },
          tooltip: {},
          color:["#37a2da","#ffdb5c","#e7bcf3"],
          legend: {
            icon: "circle",
            x: "center",
            data: ["移动", "电信", "联通"],
            selected: {
              GDP: false,
              金融: false,
              房地产: false,
            },
            textStyle: {
            color: "#fff",
          },
          },
          calculable: true,
          grid: {
            // 调整曲线图高度
            // top: 240,
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value.replace("\n", "");
                  },
                },
              },
            },
          },
          xAxis: [
            {
              show: false, // 不显示文字
              type: "category",
              axisLabel: { interval: 0 },
              data: [
                "中核集团",
                "中国华能",
                "中国大唐",
                "中国华电",
                "国家电投",
                // "中国三峡集团",
                // "国家能源集团",
                "国投集团",
                "华润集团",
                "中国电建",
                "中国能建",
                "中广核集团",
                "其他能源集团"
              ],
              splitLine: { show: false }
            },
          ],
          yAxis: [
            {
              type: "value",
              // name: "GDP（亿元）",
              axisLabel: {
                // interval: 0,
                textStyle: {
                  color: "#ddd",
                },
              },
            },
          ],
          series: [
            { name: "移动", type: "bar" },
            { name: "电信", type: "bar" },
            { name: "联通", type: "bar" },
            {
              // name: "GDP占比",
              type: "pie",
              roseType: "angle",
              // 饼图初始位置
            //   center: ["50%", "22%"],
              center: ["200%", "22%"],
              radius:  60,
              z: 100,
            },
          ]
        },
        options: [
          // {
          //   // title: { text: "2007全国宏观经济指标" },
          //   series: [
          //     { data: dataMap.dataPI["2007"] },
          //     { data: dataMap.dataSI["2007"] },
          //     { data: dataMap.dataTI["2007"] },
          //     {
          //       data: [
          //         { name: "移动", value: dataMap.dataPI["2007sum"] },
          //         { name: "电信", value: dataMap.dataSI["2007sum"] },
          //         { name: "联通", value: dataMap.dataTI["2007sum"] },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   // title: { text: "2008全国宏观经济指标" },
          //   series: [
          //     { data: dataMap.dataPI["2008"] },
          //     { data: dataMap.dataSI["2008"] },
          //     { data: dataMap.dataTI["2008"] },
          //     {
          //       data: [
          //         { name: "移动", value: dataMap.dataPI["2008sum"] },
          //         { name: "电信", value: dataMap.dataSI["2008sum"] },
          //         { name: "联通", value: dataMap.dataTI["2008sum"] },
          //       ],
          //     },
          //   ],
          // },
          {
            // title: { text: "2009全国宏观经济指标" },
            series: [
              { data: dataMap.dataPI["2009"] },
              { data: dataMap.dataSI["2009"] },
              { data: dataMap.dataTI["2009"] },
              {
                data: [
                  { name: "移动", value: dataMap.dataPI["2009sum"] },
                  { name: "电信", value: dataMap.dataSI["2009sum"] },
                  { name: "联通", value: dataMap.dataTI["2009sum"] },
                ],
              },
            ],
          },
          {
            // title: { text: "2010全国宏观经济指标" },
            series: [
              { data: dataMap.dataPI["2010"] },
              { data: dataMap.dataSI["2010"] },
              { data: dataMap.dataTI["2010"] },
              {
                data: [
                  { name: "移动", value: dataMap.dataPI["2010sum"] },
                  { name: "电信", value: dataMap.dataSI["2010sum"] },
                  { name: "联通", value: dataMap.dataTI["2010sum"] },
                ],
              },
            ],
          },
          {
            // title: { text: "2011全国宏观经济指标" },
            series: [
              { data: dataMap.dataPI["2011"] },
              { data: dataMap.dataSI["2011"] },
              { data: dataMap.dataTI["2011"] },
              {
                data: [
                  { name: "移动", value: dataMap.dataPI["2011sum"] },
                  { name: "电信", value: dataMap.dataSI["2011sum"] },
                  { name: "联通", value: dataMap.dataTI["2011sum"] },
                ],
              },
            ],
          },
        ],
      };  
      this.chart.setOption(option);
    },
  },
  destroyed() {
    window.onresize = null;
  },
};
</script>

<style lang="scss" scoped>
</style>