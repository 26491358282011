<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 style="width:33.3%;height:.0625rem;" />
          <div class="d-flex jc-center">
            <dv-decoration-8 :color="['#568aea', '#000000']" style="width:2.5rem;height:.625rem;" />
            <div class="title">
              <span class="title-text"><img :src="sUrl" style="width:300px;height:50px;"></span>
              <dv-decoration-5
                class="title-bototm"
                :reverse="true"
                :color="['#008B8B', '#67a1e5']"
                style="width:8.125rem;height:.5rem;"
              />
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#000000']"
              style="width:2.5rem;height:.625rem;"
            />
          </div>
          <dv-decoration-10 style="width:33.3%;height:.0625rem; transform: rotateY(180deg);" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 40%">
            <div
              class="react-right ml-4"
              style="width: 6.25rem; text-align: left;background-color: "
            >
               <!-- // 记得揭开 -->
              <!-- <span class="react-before"></span> -->
              
              <!-- <dv-decoration-1 style="width:300px;height:50px;" /> -->
              <!-- <img src="/home/lbc/Znrh/src/assets/card11.png" alt=""> -->
              <span class="text"><img :src="imgUrl" style="width:350px;height:60px;"></span>
            </div>
            <div class="react-right ml-3" style="background-color: ">
              <dv-decoration-3 style="width:250px;height:30px; margin-left:60px" />
              <!-- <span class="text colorBlue">数据分析2 #0f1325;</span> -->
              <span class="text colorBlue"></span>
            </div>
          </div>
          <div style="width: 40%" class="d-flex">
            <!-- <div class="react-left bg-color-blue mr-3">
              <span class="text fw-b">vue-big-screen</span>
            </div>-->
            <div class="react-left mr-3" style="background-color: ">
              <dv-decoration-3 style="width:250px;height:30px;" />
              <!-- <span class="text fw-b"></span> -->
            </div>
            <div
              class="react-left mr-4"
              style="width: 6.25rem; background-color:  text-align: right;"
            > 
              <!-- // 记得揭开 -->
              <!-- <span class="react-after"></span> -->
              <span class="text">{{ year }}年&nbsp;{{ month }}月&nbsp;{{ day }}日&nbsp;{{ weekday }}&nbsp;{{ date }}</span>
              
            </div>
          </div>
        </div>
        <div class="body-box">

          <!-- 各集团接入电厂统计 -->
          <div class="content-box">
            <div>
              <dv-border-box-1>
                <center />
                <!-- <centerLeft1 /> -->
              </dv-border-box-1>
            </div>
            <div>
             
            </div>
           <!-- 大地图 -->
            <div class="map-box">
              <!-- <dv-border-box-6> -->
              <mapt/>
              <!-- </dv-border-box-6> -->
            </div>

            <!-- 中间 -->
            <!-- <div> -->
              <!-- <mapChart/> -->
            <!-- </div> -->

            <!-- 各集团电厂在线率 -->
            <div class="right-box">
              <dv-border-box-1>
                <centerRight1 />
              </dv-border-box-1>
            </div>
          </div>
          
          <!-- 各集团电厂分类统计 -->
          <div class="cont-box">
            <dv-border-box-1>
              <centerLeft1 />
            </dv-border-box-1>
          </div>

          <!-- 数据统计图 -->
          <!-- <div class="bototm-box">
            <dv-border-box-1> -->
              <!-- <bottomRight /> -->
              <!-- <bottomLeft /> -->
            <!-- </dv-border-box-1>
          </div> -->

          <!-- VPDN监控 -->
          <div class="ront-box">
            <dv-border-box-1>
              <bottomB />
            </dv-border-box-1>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import centerLeft1 from "./centerLeft1";
// import centerLeft2 from "./centerLeft2";
// import centerRight1 from "./new3";
import centerRight1 from "./centerRight1";
// import centerRight2 from "./centerRight2";
import center from "./center";
// import bottomLeft from "./bottomLeft";
// html版本
// import mapt from "./map";

// import mapt from "./map_eight";
// import mapt from "./map_eleven";
import mapt from "./map_twelve";
// import mapt from "./map_fiveteen";
/////*******百度地图 inmap */
// import mapChart from "@/components/echart/map/map";
// import bottomB from "./bottomBot";
import bottomB from "./bottomBot";
export default {
  data() {
    return {
      loading: true,
      date: "",  //yyyy-MM-dd HH:mm:ss
      year: new Date().getFullYear(),  //yyyy-MM-dd HH:mm:ss
      month: new Date().getMonth() + 1,  //yyyy-MM-dd HH:mm:ss
      day: new Date().getDate(),  //yyyy-MM-dd HH:mm:ss
      weekday: "", 
      dday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      imgUrl:require("../assets/2h.png"),
      sUrl:require("../assets/3d.png"),
    };
  },
  components: {
    centerLeft1,
    // centerLeft2,
    centerRight1,
    mapt,
    /////*******百度地图 inmap */
    // mapChart,

    center,
    // bottomLeft,
    bottomB
    // bottomRight
  },
  mounted() {
    this.cancelLoading();
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      // _this.date = new Date(); // 修改数据date
      var date = new Date();
      var time = [
          date.getHours().toString().length===1?'0'+date.getHours().toString():date.getHours().toString(),
          date.getMinutes().toString().length===1?'0'+date.getMinutes().toString():date.getMinutes().toString(),
          date.getSeconds().toString().length===1?'0'+date.getSeconds().toString():date.getSeconds().toString(),
      ];
      _this.date = time.join(' :');
      // _this.year = new Date().getFullYear()
      // _this.month = new Date().getMonth() + 1;
      // _this.day = new Date().getDate()
      _this.weekday = this.dday[new Date().getDay()]
      // getMonth()
      // _this.date = new Date(); // 修改数据date
    }, 1000)
    
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    
  },
};
</script>

<style lang="scss">
@import "../assets/scss/index.scss";
#id_clock {
    width: 100%;
    /*height: 80%;*/
    padding: 5px 10px;
    font-size: 20px;
    color: 	#000000;
    font-family: myFirstFont;
}
</style>